<template>
  <div>
    <div style="width: 100%; height: 100px;">
      <div style="width: 20%; height: 100px; float: left;">
        <img src="../../assets/img/logo.jpg" style="width: 300px; height: 100px;" />
      </div>
      <div style="width: 80%; height: 100px; float: left; position: relative;">
        <div style="width: 50%; height: 80px; position: absolute; right: 100px; bottom: -10px;">
          <el-menu :default-active="activeIndex" mode="horizontal">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">公司简介</el-menu-item>
            <el-menu-item index="3">公司动态</el-menu-item>
            <el-menu-item index="4">产品介绍</el-menu-item>
            <el-menu-item index="5">联系我们</el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 800px; display: flex; justify-content: center; align-items: center;">
      <div
        style="width: 50%; height: 100px; background-color: #409EFF; border-radius: 100px; text-align: center; line-height: 100px; font-size: 50px; color: white; font-weight: bold;">
        网站装修中，敬请期待...
      </div>
    </div>
    <div
      style="position: absolute; left: 0; bottom: 0; width: 100%; height: 100px; display: flex; justify-content: center; align-items: center;">
      <div style="width: 420px; height: 30px; font-size: 14px; color: #303133;">
        <a href="https://beian.miit.gov.cn" target="_blank">桂ICP备2024026374号-2</a>
        <a target="_blank" style="margin-left: 20px"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010802000925"><img
            src="../../assets/img/wangan.png" style="margin-right: 5px; margin-bottom: -4px" />桂公网安备 45010802000925号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      activeIndex: '1'
    };
  },
  created () {
  },
  methods: {
  },
};
</script>

<style>
body, html {
  padding: 0;
  margin: 0;
}
</style>